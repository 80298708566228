import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';

function Layout({ children }) {
    return (
        <div>
            {/* Add header or navigation here if needed */}
            <Navigation />
            {/* Main content (the page content) */}
            <main>
                {children}
            </main>

            {/* Footer Section */}
            <footer className="bg-secondary text-white py-3 mt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p className="mb-0">&copy; {new Date().getFullYear()} resistor-calculator.com</p>
                        </div>
                        <div className="col-12 col-md-6 text-md-end">
                            <Link className="text-white me-3" to="/">Back to Resistor Calculator</Link>
                            <Link className="text-white me-3" to="/privacy">Privacy</Link>
                            {/* <Link className="text-white" to="/imprint">Imprint</Link> */}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Layout;
