import React from 'react';

function Privacy() {
  return (
<div className="container col-md-8 mt-4">
        <h1>Privacy Policy for Resistor-Calculator.com</h1>

        <p>At Resistor-Calculator.com, we are committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website. By accessing and using Resistor-Calculator.com, you agree to the terms of this Privacy Policy.</p>

        <h2>1. Information We Collect</h2>
        <p>We collect information from you in the following ways:</p>

        <h3>a. Google Analytics</h3>
        <p>We use <strong>Google Analytics</strong> to track and analyze usage patterns on Resistor-Calculator.com. Google Analytics collects anonymous information, including:</p>
        <ul>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>IP address</li>
            <li>Referring website</li>
            <li>Pages visited and time spent on the site</li>
            <li>Other relevant usage data</li>
        </ul>
        <p>This information helps us improve the functionality and performance of our website.</p>

        <h3>b. Google Ads</h3>
        <p>Resistor-Calculator.com serves advertisements through <strong>Google AdSense</strong>. Google may collect and use your personal data to display personalized ads. Google uses cookies to show relevant ads based on your visit to this website and other sites on the internet.</p>
        <p>For more information about how Google uses your data, please refer to <a href="https://policies.google.com/technologies/partner-sites" target="_blank">Google's Privacy & Terms</a>.</p>

        <h3>c. Cookies</h3>
        <p>We use cookies to enhance your experience on our website. Cookies are small text files stored on your device that help us provide a personalized browsing experience. These cookies may be used to:</p>
        <ul>
            <li>Remember user preferences</li>
            <li>Provide relevant content and ads</li>
            <li>Track website analytics and performance through Google Analytics</li>
        </ul>
        <p>You can choose to disable cookies through your browser settings, but this may affect the functionality of Resistor-Calculator.com.</p>

        <h2>2. How We Use Your Information</h2>
        <p>The information we collect is used for the following purposes:</p>
        <ul>
            <li><strong>Analytics and Performance Monitoring:</strong> To understand how visitors use our website, improve the user experience, and ensure website functionality.</li>
            <li><strong>Ad Personalization:</strong> To display ads relevant to you through Google AdSense. Google uses cookies to serve ads based on your visit to our website and other websites on the internet.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>We do not sell, trade, or rent your personal information to third parties. However, we may share non-personally identifiable information with third parties for the following purposes:</p>
        <ul>
            <li><strong>Service Providers:</strong> Such as Google Analytics and Google Ads, to track website usage and display relevant ads.</li>
            <li><strong>Legal Requirements:</strong> We may disclose personal information if required by law or in response to valid requests by public authorities.</li>
        </ul>

        <h2>4. Google Analytics and Advertising</h2>

        <h3>a. Google Analytics</h3>
        <p>As mentioned, we use Google Analytics to collect anonymous usage data. You can opt-out of Google Analytics tracking by installing the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics Opt-Out Browser Add-on</a>.</p>

        <h3>b. Google Ads</h3>
        <p>Google uses cookies to serve ads based on your prior visits to our site or other websites. Users can opt-out of personalized advertising by visiting the <a href="https://adssettings.google.com/authenticated" target="_blank">Ads Settings</a> or using <a href="https://optout.networkadvertising.org/" target="_blank">Google’s opt-out tools</a>.</p>

        <h2>5. Third-Party Links</h2>
        <p>Resistor-Calculator.com may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of these third-party websites before providing any personal information.</p>

        <h2>6. Data Security</h2>
        <p>We take reasonable precautions to protect the information we collect. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

        <h2>7. Your Rights</h2>
        <p>As a user of Resistor-Calculator.com, you have the following rights regarding your personal information:</p>
        <ul>
            <li><strong>Access:</strong> You have the right to request access to the personal data we hold about you.</li>
            <li><strong>Rectification:</strong> You have the right to correct inaccurate or incomplete personal data.</li>
            <li><strong>Deletion:</strong> You have the right to request the deletion of your personal data under certain conditions.</li>
        </ul>
        <p>If you would like to exercise any of these rights, please contact us using the information provided below.</p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to check this page periodically to stay informed about our privacy practices.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, or if you would like to exercise your rights regarding your personal data, please contact us at:</p>
        <p><strong>Email:</strong> info@resistor-calculator.com</p>
        <p><strong>Last Updated:</strong> September 2024</p>
    </div>
  );
}

export default Privacy;
