import React from 'react';
import './Navigation.css'; // Optional: for custom styles
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <header className="bg-primary py-1 pt-2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-12 col-md-10 d-flex align-items-center">
          <Link to="/"><img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" className="me-3" style={{ height: "50px" }} /></Link>
            <div>
              <h1 className="text-white mb-1 text-left">
                <Link to="/" className="text-white text-decoration-none">Resistor Color Code Calculator</Link>
              </h1>
              <h2 className="mt-0 text-left">Decode resistor color codes. Supports 3, 4, 5, and 6 band resistors.</h2>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="container-fluid">

      </div>

    </header>



  );
}

export default Navigation;
