import React from 'react';
import {Adsense} from '@ctrl/react-adsense';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Calculator from './Calculator'; // Your calculator component
import Privacy from './Privacy'; // Privacy page
import Imprint from './Imprint'; // Imprint page
import Layout from './Layout'; // The new Layout component



function App() {
  return (
    <Router>
      <Layout>
        {/* Define routes using Routes instead of Switch */}
        <Routes>
          {/* Home page */}
          <Route path="/" element={<Calculator />} />
          
          {/* Privacy page */}
          <Route path="/privacy" element={<Privacy />} />
          
          {/* Imprint page */}
          {/* <Route path="/imprint" element={<Imprint />} /> */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
