import React from 'react';
import './Navigation.css'; // Optional: for custom styles
import {Adsense} from '@ctrl/react-adsense';

function ResistorInfoText() {
    return (
        <div className="row justify-content-center mt-4">
            <div className="col-12">
                <p className="text-left">
                Whether you're a student, electronics engineer, hobbyist Arduino or ESP32 maker, or someone exploring electronics, resistors will be a component of your projects. If you're working with Arduino projects, Raspberry Pi, or building electronic circuits, understanding resistor color codes is important for circuit design or breadboard setups and ensures that components work properly.
                </p>

                <div className="container my-2">
                    <div className="row">
                        <div className="col-12 col-md-6 text-center border-md-end mb-3 mb-md-0">
                            <a href="https://play.google.com/store/apps/details?id=com.vivid_planet.resistor&amp;utm_source=global_co&amp;utm_medium=prtnr&amp;utm_content=Mar2515&amp;utm_campaign=PartBadge&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img className="img-fluid" alt="Get it on Google Play" src={`${process.env.PUBLIC_URL}/badge-google-play.png`} />
                            </a>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <a href="https://geo.itunes.apple.com/us/app/resistor-code-calculator/id804698595?mt=8">
                                <img className="img-fluid" alt="Download on the App Store" src={`${process.env.PUBLIC_URL}/badge-appstore.png`} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container my-2 mt-4 ">
                <h3>What are resistor and why color codes are used?</h3>
<p>Resistors limit the flow of current in a circuit, control voltage, and protect components. They are found in almost every electronic device. Due to their small size, the standard axial lead resistors use a color-coding system to indicate their values.</p>

<p>For very small electronic components a color code instead of printed text is used to indicate the value, rating or and tolerance.</p>
<p>Resistors come in variations with 4, 5 or more color bands where a 4-band color code is most common. With 4 bands the first and second band represent the first and second significant digit of the ohm value, the 3 band is the decimal multiplier. Next comes a small gap - helping you to distinguish left and right of the component - and finally the fourth band indicating the tolerance of the resistor.</p>

<div className="container text-center mb-3">
<Adsense
  client="ca-pub-6506748959449825"
  slot="9769703565"
  style={{ display: 'block' }}
  layout="in-article"
  format="fluid"
/>
</div>

<h3>How to Read Resistor Color Codes</h3>

<p>Resistor color codes are a standardized way to indicate a resistor's value, rating, and tolerance. Each color band represents a specific digit or multiplier. The most common resistors have 4 or 5 color bands, but there are also variants with 3 or 6:</p>

<ul>
  <li>In a <strong>3-band resistor</strong>, the first two bands represent significant digits and the third band is the multiplier. The tolerance of these resistors is always +/-20%.</li>
  <li>In a <strong>4-band resistor</strong>, the first two bands represent significant digits, the third band is the multiplier, and the fourth band shows the tolerance.</li>
  <li><strong>5-band resistors</strong> provide more precision, with the first three bands representing significant digits, the fourth band as the multiplier, and the fifth band indicating tolerance.</li>
  <li><strong>6-band resistors</strong> provide the same precision as 5-bandd, with the first three bands representing significant digits, the fourth band as the multiplier, and the fifth band indicating tolerance. The sixth band is the temperature coefficient (TCR) and tells how much the resistor value changes as temperature changes.</li>

</ul>

<p>To easily find the value of a resistor, you can click on the colors printed on your resistor, and the calculator will display the value and tolerance for you. This tool is especially helpful if you have trouble remembering the codes or are working with resistors that have faded colors.</p>

<p>Whether you’re prototyping, repairing, or just learning, knowing how to read resistor color codes will make working with electronics easier and more effective.</p>

<h3>More information about resistor color codes (external links):</h3>
					<ul>
						<li><a href="https://en.wikipedia.org/wiki/Electronic_color_code" target="_blank">Wikipedia:
								Electronic color code</a></li>
						<li><a href="http://www.resistorguide.com/resistor-color-code/" target="_blank">Resistor color
								codes explained</a></li>
						<li><a href="http://www.instructables.com/id/How-to-read-color-codes-from-resistors/"
								target="_blank">Instructable: How to read color codes from resistors</a></li>
					</ul>


                </div>                

            </div>
        </div>
    );
}



export default ResistorInfoText;
