import React, { useState, useEffect } from 'react';
import ResistorInfoText from './ResistorInfoText';
import { Adsense } from '@ctrl/react-adsense';


const colorCodes = {
  black: { value: 0, multiplier: 1, tempCoeff: 250 }, // 250 ppm/K for temperature coefficient
  brown: { value: 1, multiplier: 10, tolerance: 1, tempCoeff: 100 }, // 100 ppm/K
  red: { value: 2, multiplier: 100, tolerance: 2, tempCoeff: 50 }, // 50 ppm/K
  orange: { value: 3, multiplier: 1000, tempCoeff: 15 }, // 15 ppm/K
  yellow: { value: 4, multiplier: 10000, tempCoeff: 25 }, // 25 ppm/K
  green: { value: 5, multiplier: 100000, tolerance: 0.5, tempCoeff: 20 }, // 20 ppm/K
  blue: { value: 6, multiplier: 1000000, tolerance: 0.25, tempCoeff: 10 }, // 10 ppm/K
  violet: { value: 7, multiplier: 10000000, tolerance: 0.1, tempCoeff: 5 }, // 5 ppm/K
  gray: { value: 8, multiplier: 100000000, tolerance: 0.05, tempCoeff: 1 }, // 1 ppm/K
  white: { value: 9, multiplier: 1000000000 },
  gold: { value: -1, multiplier: 0.1, tolerance: 5 },
  silver: { value: -2, multiplier: 0.01, tolerance: 10 }
};

function ColorGrid({ label, selectedColor, onSelect, options, type }) {
  return (
    <div className="container m-0 p-0">
      <div className="bandlabel py-2">
        <p className='text-center m-0 p-0'>{label}</p></div>
      {/* <div className="row d-flex align-items-stretch flex-wrap"> */}
      {options.map((colorKey) => {
        // Combine validity checks based on type
        const isValid =
          (type === 'tolerance' && ['brown', 'red', 'green', 'blue', 'violet', 'gray', 'gold', 'silver'].includes(colorKey)) ||
          (type === 'multiplier' && ['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gold', 'silver'].includes(colorKey)) ||
          (type === 'tempCoeff' && ['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray'].includes(colorKey)) || // Add valid colors for tempCoeff
          (type !== 'tolerance' && type !== 'multiplier');  // Normal bands (Band 1, 2, 3) are always valid

        return (
          <div
            key={colorKey}
            className={`text-nowrap m-0 color-cell ${colorKey} ${selectedColor === colorKey ? 'selected' : ''} `}
            style={isValid ? {} : { cursor: 'default', opacity: 0.0 }}  // Disable interaction for invalid colors
            onClick={isValid ? () => onSelect(colorKey) : null}  // Only allow click if valid
            onMouseEnter={isValid ? () => {/* Optional hover effect */ } : null}  // Disable hover effect if not valid
          >
            {type === 'multiplier' && colorCodes[colorKey].multiplier !== undefined
              ? ("x " + formatMultiplier(colorCodes[colorKey].multiplier))
              : type === 'tolerance' && colorCodes[colorKey].tolerance !== undefined
                ? `±${colorCodes[colorKey].tolerance}%`
                : type === 'tempCoeff' && colorCodes[colorKey].tempCoeff !== undefined
                  ? `${colorCodes[colorKey].tempCoeff} ppm/K` // Display tempC                  
                  : colorCodes[colorKey].value !== undefined
                    ? colorCodes[colorKey].value
                    : colorKey}
          </div>
        );
      })}
    </div>
  );
}

// Helper function to format large multiplier values
const formatMultiplier = (multiplier) => {
  if (multiplier >= 1000000) {
    return `${multiplier / 1000000}MΩ`; // Convert to Mega (MΩ)
  } else if (multiplier >= 1000) {
    return `${multiplier / 1000}KΩ`; // Convert to Kilo (KΩ)
  } else {
    return `${multiplier}Ω`; // Default case
  }
};

function formatValue(value) {
  if (value >= 1000000000) {
    let formattedValue = (value / 1000000000).toFixed(2);
    return formattedValue.endsWith(".00") ? formattedValue.slice(0, -3) + ' G' : formattedValue + ' G';
  }
  else if (value >= 1000000) {
    let formattedValue = (value / 1000000).toFixed(2);
    return formattedValue.endsWith(".00") ? formattedValue.slice(0, -3) + ' M' : formattedValue + ' M';
  } else if (value >= 1000) {
    let formattedValue = (value / 1000).toFixed(2);
    return formattedValue.endsWith(".00") ? formattedValue.slice(0, -3) + ' K' : formattedValue + ' K';
  } else {
    return value % 1 === 0 ? value + ' ' : value.toFixed(2) + ' ';
  }
}

function App() {
  const [bandMode, setBandMode] = useState(4); // Default mode is 4-band
  const [band1, setBand1] = useState('red');
  const [band2, setBand2] = useState('red');
  const [band3, setBand3] = useState('black');
  const [multiplier, setMultiplier] = useState('brown');
  const [tolerance, setTolerance] = useState('gold');
  const [tempCoeff, setTempCoeff] = useState('brown'); // For 6-band resistors

  // Set default tolerance when band mode changes
  useEffect(() => {
    if (bandMode === 3) {
      setTolerance('none'); // No tolerance band for 3-band resistors
    } else if (bandMode === 4 && tolerance === 'none') {
      setTolerance('gold'); // Default to gold (5%) for 4-band resistors
    } else if (bandMode === 5 && tolerance === 'none') {
      setTolerance('red'); // Default to red (2%) for 5-band resistors
    } else if (bandMode === 6 && tolerance === 'none') {
      setTolerance('brown'); // Default to brown (1%) for 6-band resistors
    }
  }, [bandMode, tolerance]); // This will run whenever bandMode or tolerance changes

  // useEffect(() => {
  //   console.log('Resistor bands updated');
  //   return () => {
  //     console.log('Cleaning up resistor bands');
  //   };
  // }, [band1, band2, band3, multiplier, tolerance, tempCoeff, bandMode]);


  // Calculation logic for different modes
  const calculateResistance = () => {
    const firstDigit = colorCodes[band1].value;
    const secondDigit = colorCodes[band2].value;
    const thirdDigit = bandMode >= 5 ? colorCodes[band3].value : 0; // 5-band mode includes 3rd band for digit
    const multiplierValue = colorCodes[multiplier].multiplier;
    const toleranceValue = bandMode === 3 ? 20 : (colorCodes[tolerance]?.tolerance || 20); // Default 20% tolerance for 3-band
    const tempCoeffValue = bandMode === 6 ? colorCodes[tempCoeff].tempCoeff : null; // Only for 6-band

    let resistanceValue = 0;
    if (bandMode === 3 || bandMode === 4) {
      resistanceValue = (firstDigit * 10 + secondDigit) * multiplierValue;
    } else if (bandMode === 5 || bandMode === 6) {
      resistanceValue = (firstDigit * 100 + secondDigit * 10 + thirdDigit) * multiplierValue;
    }

    return { resistanceValue, toleranceValue, tempCoeffValue };
  };


  // Function to render the resistor bands based on the bandMode
  const renderResistorBands = () => {

    let bands = [];

    bands.push(<div key="band1" className={`resistor-band band-1 ${band1}`}>{colorCodes[band1].value}</div>);
    bands.push(<div key="band2" className={`resistor-band band-2 ${band2}`}>{colorCodes[band2].value}</div>);
    bands.push(<div key="band3" className={`resistor-band band-3 ${bandMode >= 5 ? band3 : multiplier}`}>{bandMode >= 5 ? colorCodes[band3].value : colorCodes[multiplier].value}</div>)

    if (bandMode >= 4) {
      bands.push(<div key="tolerance" className={`resistor-band band-5 ${tolerance}`}></div>);
    }

    if (bandMode >= 5) {
      bands.push(<div key="multiplier" className={`resistor-band band-4 ${multiplier}`}>{colorCodes[multiplier].value}</div>);
    }

    if (bandMode === 6) {
      bands.push(<div key="tempCoeff" className={`resistor-band band-6 ${tempCoeff}`}></div>);
    }

    return bands;
  };

  // Function to render the resistor bands based on the bandMode
  const renderResistorCalculation = () => {

    let html = '';
    html += colorCodes[band1].value + "" + colorCodes[band2].value + "";
    if (bandMode >= 5) {
      html += colorCodes[band3].value + "";
    }
    html += " x 10^" + colorCodes[multiplier].value + " = "
    html += colorCodes[band1].value + "" + colorCodes[band2].value + "";
    if (bandMode >= 5) {
      html += colorCodes[band3].value + " ";
    }
    html += " x " + formatMultiplier(colorCodes[multiplier].multiplier) + " =";

    return html;
  };

  const result = calculateResistance();

  return (
    <div className="container-fluid p-0 m-0">

      <div className="container-fluid">
        <div className="row">
          {/* Left column for Google Adsense */}
          <div className="col-md-2 ad-column pt-4">
            <div id="google-ads-left">
            <Adsense
              client="ca-pub-6506748959449825"
              slot="8335232775"
              style={{ display: 'block' }}
              format="auto"
              responsive="true"
            />
            </div>
          </div>

          {/* Main resistor calculator content with max-width */}

          <div className="col-md-8 resistor-calculator-container">

            <div className='container my-4'><h2 className='subtitle text-center'>
              Use this tool to convert resistor color codes into resistor ohm value and tolerance.
              Color banded axial lead resistors with 3, 4, 5 and 6 bands are supported.</h2>

            </div>

            {/* Mode Selection Bar */}
            <div className="row justify-content-center my-3">
              <div className="col-auto">
                <div className="btn-group" role="group" aria-label="Band Modes">
                  <button
                    type="button"
                    className={`btn btn-secondary ${bandMode === 3 ? 'active' : ''}`}
                    onClick={() => setBandMode(3)}
                  >
                    3 Band
                  </button>
                  <button
                    type="button"
                    className={`btn btn-secondary ${bandMode === 4 ? 'active' : ''}`}
                    onClick={() => setBandMode(4)}
                  >
                    4 Band
                  </button>
                  <button
                    type="button"
                    className={`btn btn-secondary ${bandMode === 5 ? 'active' : ''}`}
                    onClick={() => setBandMode(5)}
                  >
                    5 Band
                  </button>
                  <button
                    type="button"
                    className={`btn btn-secondary ${bandMode === 6 ? 'active' : ''}`}
                    onClick={() => setBandMode(6)}
                  >
                    6 Band
                  </button>
                </div>
              </div>
            </div>

            <div className="container resistor-container">
              <div className="resistor-lead"></div>
              <div className="resistor-body">
                {renderResistorBands()}
              </div>
              <div className="resistor-lead"></div>
            </div>

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <small>{renderResistorCalculation()}</small>
                </div>
              </div>
            </div>



            {/* Display the calculated value inside a formatted box */}
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6 mb-3">
                  <div className="card">
                    <div className="card-body d-flex flex-column justify-content-center align-items-center m-1 p-1">
                      <span className="h4 mb-0">{formatValue(result.resistanceValue)}Ω <small>± {result.toleranceValue}%</small></span>

                      {result.tempCoeffValue !== null ? <span className="h6 mb-0"><small>(TCR: {result.tempCoeffValue} ppm/K)</small></span> : ''}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center ">
              <div className="text-nowrap col p-0">
                <ColorGrid
                  label="BAND 1"
                  selectedColor={band1}
                  onSelect={setBand1}
                  options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray', 'white']}
                />
              </div>
              <div className="text-nowrap col p-0">
                <ColorGrid
                  label="BAND 2"
                  selectedColor={band2}
                  onSelect={setBand2}
                  options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray', 'white']}
                />
              </div>

              {bandMode >= 5 && (
                <div className="text-nowrap col p-0">
                  <ColorGrid
                    label="BAND 3"
                    selectedColor={band3}
                    onSelect={setBand3}
                    options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray', 'white']}
                  />
                </div>
              )}

              <div className="text-nowrap col p-0">
                <ColorGrid
                  label="MULT."
                  selectedColor={multiplier}
                  onSelect={setMultiplier}
                  options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray', 'white', 'gold', 'silver']}  // Pass all colors
                  type="multiplier"
                />
              </div>

              {bandMode !== 3 && (
                <div className="text-nowrap col p-0">
                  <ColorGrid
                    label="TOL."
                    selectedColor={tolerance}
                    onSelect={setTolerance}
                    options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray', 'white', 'gold', 'silver']}  // Pass all colors
                    type="tolerance"
                  />
                </div>
              )}

              {bandMode === 6 && (
                <div className="text-nowrap col p-0">
                  <ColorGrid
                    label="TCR"  // Label for Temperature Coefficient
                    selectedColor={tempCoeff}
                    onSelect={setTempCoeff}
                    options={['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'violet', 'gray']}  // Valid colors for temperature coefficient
                    type="tempCoeff"  // Pass a new type to differentiate temperature coefficient
                  />
                </div>
              )}



            </div>

            <ResistorInfoText />

          </div>

          {/* Right column for Google Adsense */}
          <div className="col-md-2 ad-column pt-4">
          <div id="google-ads-right">
            <Adsense
              client="ca-pub-6506748959449825"
              slot="8335232775"
              style={{ display: 'block' }}
              format="auto"
              responsive="true"
            />
            </div>
          </div>
        </div>
      </div>





    </div>
  );
}

export default App;
